import { create_private_room } from './chat_utils';

export default class ChatAddRoom {
  constructor(opts) {
    this.user = opts.user;
    this.user_email = opts.user_email;
    this.users_list = [];

    frappe.call({
      method: 'frappe.client.get_list',
      args: {
        doctype: 'User',
        fields: ['email'],
        filters: { enabled: 1 }, // Lấy danh sách người dùng đã được kích hoạt
        limit_page_length: 9999 // Lấy tất cả bản ghi (giới hạn lớn để đảm bảo lấy hết)
      },
      callback: (response) => {
        if (response.message) {
          // Lấy danh sách email từ response.message
          this.users_list = response.message.map(user => user.email);

          // Thêm 'Administrator' vào danh sách nếu không tồn tại
          if (!this.users_list.includes('Administrator')) {
            this.users_list.push('Administrator');
          }

          // Lọc bỏ email của user hiện tại (opts.user_email)
          this.users_list = this.users_list.filter(email => email !== opts.user_email);

          // Gọi hàm setup() sau khi đã lấy được danh sách email
          this.setup();
        }
      }
    });
  }

  async setup() {
    this.add_room_dialog = new frappe.ui.Dialog({
      title: __('New Chat Room'),
      fields: [
        {
          label: __('Room Type'),
          fieldname: 'type',
          fieldtype: 'Select',
          options: ['Group', 'Direct'],
          default: 'Group',
          onchange: () => {
            const type = this.add_room_dialog.get_value('type');
            const is_group = type === 'Group';
            this.add_room_dialog.set_df_property('room_name', 'reqd', is_group);
            this.add_room_dialog.set_df_property('users', 'reqd', is_group);
            this.add_room_dialog.set_df_property('user', 'reqd', !is_group);
          },
          reqd: true,
        },
        {
          label: __('Room Name'),
          fieldname: 'room_name',
          fieldtype: 'Data',
          depends_on: "eval:doc.type == 'Group'",
          reqd: true,
        },
        {
          label: __('Users'),
          fieldname: 'users',
          fieldtype: 'MultiSelectPills',
          options: this.users_list,
          depends_on: "eval:doc.type == 'Group'",
          reqd: true,
        },
        {
          label: __('User'),
          fieldname: 'user',
          fieldtype: 'Link',
          options: 'User',
          depends_on: "eval:doc.type == 'Direct'",
        },
      ],
      action: {
        primary: {
          label: __('Create'),
          onsubmit: (values) => {
            let users = this.add_room_dialog.fields_dict.users.get_values();
            let room_name = values.room_name;
            if (values.type === 'Direct') {
              users = [values.user];
              room_name = 'Direct Room';
            }
            this.handle_room_creation(room_name, users, values.type);
            this.add_room_dialog.hide();
          },
        },
      },
    });
  }

  show() {
    this.add_room_dialog.show();
  }

  async handle_room_creation(room_name, users, type) {
    try {
      await create_private_room(room_name, users, type);
      this.add_room_dialog.clear();
    } catch (error) {
      //pass
    }
  }
}
